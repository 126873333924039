import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginScreen from "./containers/LoginScreen/LoginScreen";
import './App.css'
import CostOptimizer from "./containers/CostOptimizer/CostOptimizer";
import ProtectedRoute from "./helpers/ProtectedRoutes";
import DataStudioReport from "./containers/DataStudioReport";
import SideNavDrawer from './components/SideNavDrawer'
import PasswordReset from "./containers/ForgotPassword/PasswordReset";
import { SocketContext, socket } from './helpers/socket';
import NotificationScreen from "./containers/Notifications/NotificationScreen";
import HomePage from "./containers/HomePage/HomePage";

//Import all pages and react router

function App() {
  return (
    <div className="App">
      <SocketContext.Provider value={socket}>
        <Router>
          <Routes>
            <Route path="/login" element={<LoginScreen />} />
            {/* Home Page as protected route */}
            <Route path="/optimizer" element={<ProtectedRoute />} >
              <Route path="/optimizer" element={<SideNavDrawer component={<CostOptimizer />} />} />
            </Route>

            <Route path="/home" element={<ProtectedRoute />} >
              <Route path="/home" element={<SideNavDrawer component={<HomePage />}  />} />
            </Route>

            <Route path="/notifications" element={<ProtectedRoute />} >
              <Route path="/notifications" element={<SideNavDrawer component={<NotificationScreen />} />} />
            </Route>

            <Route path="/passwordReset" element={<PasswordReset />} />

            <Route path="/report" element={<ProtectedRoute />} >
              <Route path="/report" element={<SideNavDrawer component={<DataStudioReport />} />} />
            </Route>
            <Route path="/" element={<LoginScreen />} />
          </Routes>
        </Router>
      </SocketContext.Provider>
    </div>
  );
}

export default App;
