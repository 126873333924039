import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Backdrop, Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import moment from 'moment/moment';
import { useEffect } from 'react';
import axios from 'axios';
import { a11yProps, TabPanel } from '../../components/TabsComponent';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import DataTableComponent from './DataTableComponent';
import { BACKEND_URL } from '../../constants/constants';


function NotificationsModal({ open, handleClose, data }) {
  const [loader, setLoader] = useState(false);


  const styles = {
    tabStyle: {
      color: '#cc0000',
      '&.MuiTab-root.Mui-selected': { color: '#cc0000' }
    },
    tableStyle: {
      height: '300px'
    }
  }

  const columns = [
    {
      field: 'customerCode',
      headerName: 'Customer Code',
      width: 150,
    },
    {
      field: 'customerName',
      headerName: 'Customer Name',
      width: 180,
    },

    {
      field: 'totalSentusers',
      headerName: 'Sent',
      width: 150,
    },
    {
      field: 'totalReadUsers',
      headerName: 'Delievered',
      width: 150,
    },
    {
      field: 'totalFailedUsers',
      headerName: 'Failed',
      width: 150,
    },
  ];

  const [rows, setRows] = useState([])


  useEffect(() => {
    const loginData = JSON.parse(localStorage.getItem('loginData'));
    const accessToken = loginData && loginData.accessToken;
    setLoader(true)
    axios.get(BACKEND_URL+'/api/v2/admin/notifications/getInfo?notificationID=' + JSON.stringify(data.id),
      {
        headers: {
          'Authorization': "Bearer ".concat(accessToken)
        }
      })
      .then((res) => {
        setLoader(false)
        console.log('!!!!!!!', res.data.data[0])
        setRows(res.data.data[0].customers)
      })

  }, [])

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={'md'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs TabIndicatorProps={{ style: { backgroundColor: "brown" } }} value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab style={styles.tabStyle} label="Message Details" {...a11yProps(0)} />
                <Tab style={styles.tabStyle} label="Receiver Details" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Grid sx={{ display: 'flex', flexDirection: 'column' }} item xs={6} rowGap={2}>
                <Typography variant='h6' sx={{ color: '#cc0000', fontWeight: 'bold' }}>Date :   <span style={{ color: '#000000', fontWeight: 'normal' }}>{moment(data.createdAt).format('DD-MM-YYYY')}</span></Typography>
                <Typography variant='h6' sx={{ color: '#cc0000', fontWeight: 'bold' }}>Title :   <span style={{ color: '#000000', fontWeight: 'normal' }}>{data.title}</span></Typography>
                <Typography variant='h6' sx={{ color: '#cc0000', fontWeight: 'bold' }}>Message :   <span style={{ color: '#000000', fontWeight: 'normal' }}>{data.body}</span></Typography>
              </Grid>

            </TabPanel>
            <TabPanel value={value} index={1}>
              <Grid item xs={6}>
                <Box style={{ height: '50vh', width: '100%' }}>
                  <DataTableComponent rows={rows} columns={columns} customStyles={styles.tableStyle} />
                </Box>
              </Grid>
            </TabPanel>
          </Box>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus component="label" sx={{
            textTransform: 'none', backgroundColor: '#cc0000', color: 'white', "&:hover": {
              backgroundColor: "#cc0000",
            },
            marginLeft: '3%',
            width: '80px'
          }}>
            Close
          </Button>
        </DialogActions>
        {loader &&
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        }
      </Dialog>
    </>
  )
}

export default NotificationsModal