import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContent, Grid, TextField } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux/es/exports';
import { addNewCodeAction } from '../../redux/actions/optimizationFormAction';
import { toast } from "react-toastify";

export default function CustomCodeModal({ open, handleClose, fuelType }) {
    const [codeName, setCodeName] = useState('');
    const [codeDescription, setCodeDescription] = useState('');
    const dispatch = useDispatch();
    const handleAddNewCode = () => {
        toast.configure();
        if (codeName === '' || codeName.match(/^[A-Z0-9]*$/)==null) {
            setCodeErrorText('Minimum 2 characters required and all UpperCase letters')
        }
        else {
            dispatch(addNewCodeAction({ codeName: codeName, codeDescription: codeDescription }))
            toast.success("Code Added Successfully", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000
            });
            handleClose();
        }
    }

    const [codeErrorText, setCodeErrorText] = useState('')


    const handleCodeChange = (e) => {
        if (e.target.value.length < 2 || e.target.value.match(/^[A-Z0-9]*$/)==null) {
            setCodeErrorText('Minimum 2 characters required and all UpperCase letters')
        } else {
            setCodeName(e.target.value)
            setCodeErrorText('')
        }
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Add New Custom Code
                </DialogTitle>
                <DialogContent>
                    <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                        <TextField placeholder='Add Custom Code' size='small' onChange={handleCodeChange} helperText={codeErrorText} error={codeErrorText}  />
                        <TextField multiline={true} rows={3} placeholder='Add Code Description' size='small' onChange={(e) => setCodeDescription(e.target.value)} sx={{ marginTop: '5%' }} />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAddNewCode} autoFocus component="label" sx={{
                        textTransform: 'none', backgroundColor: '#cc0000', color: 'white', "&:hover": {
                            backgroundColor: "#cc0000",
                        },
                        marginLeft: '3%',
                        width: '80px'
                    }}>
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
