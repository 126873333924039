import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { forgotPasswordService } from '../../services/forgotPassword.service'

const useStyles = {
  textField: {
    "& .MuiOutlinedInput-root": {
      color: 'green',
      borderRadius: '40px !important',
    },
    marginTop: "5%",
    input: {
      color: "#000000",
      fontWeight: "bold",
    },
  }
};


function ForgotPassword(props) {

  const navigate = useNavigate();
  const handlePasswordReset = () => {
    if (emailId !== '') {
      toast.configure();
      setLoader(true);
      forgotPasswordService(emailId).then((data) => {
        if (data.status == true) {
          setLoader(false);
          navigate('/passwordReset', {state: {email: emailId}} ) //pass email to passward reset
          toast.success("Password reset OTP sent to the email account", {
            position: toast.POSITION.TOP_LEFT,
            autoClose: 2000
          });
          props.handleCloseDialog();
        }
        else {
          setLoader(false);
          toast.warning("Something went wrong, Please refresh", {
            position: toast.POSITION.TOP_LEFT,
            autoClose: 2000
          });
        }
      }).catch((err)=>{
        toast.warning(err.response.data.data, {
          position: toast.POSITION.TOP_LEFT,
          autoClose: 2000
        });
        setLoader(false);
      });

    }
  }

  const [emailId, setEmailid] = React.useState('');
  const [loader, setLoader] = React.useState(false);

  return (
    <>
      <Dialog
        open={props.open}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle sx={{ fontWeight: "bold" }} id="responsive-dialog-title">
          Having trouble logging in?
        </DialogTitle>
        <DialogContent>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography sx={{ color: "#cc0000" }}>
              Enter business email address to reset your password.
            </Typography>

            <TextField
              size="small"
              placeholder="Your Registered Email"
              style={useStyles.textField}
              onChange={(e) => setEmailid(e.target.value)}
            />
          </Grid>
        </DialogContent>
        <DialogActions sx={{ marginBottom: "3%" }}>
          <Button
            sx={{ textTransform: "none", fontSize: 15, backgroundColor: '#cc0000' }}
            variant="contained"
            autoFocus
            onClick={handlePasswordReset}
          >
            Reset Password
          </Button>
          <Button
            onClick={props.handleCloseDialog}
            autoFocus
            sx={{ textTransform: "none", color: "#000000", fontSize: 15 }}
          >
            Close
          </Button>
        </DialogActions>
        {loader &&
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        }
      </Dialog>

    </>
  );
}

export default ForgotPassword;
