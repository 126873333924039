import React, { createContext, useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import { DisplayTable } from '../../components/DisplayTable';
import { Backdrop, Grid, TextField, Typography } from '@mui/material';
import UploadFilesModal from './UploadFilesModal';
import OptimizationForm from './OptimizationForm';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FuelSelectionModal from './FuelSelectionModal';
import { addNewCodeAction, endDateAction, optimizeFormAction, providerDiscountsAction, startDateAction } from '../../redux/actions/optimizationFormAction'
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { submitFormData } from '../../services/optimizationForm.service';
import { getAllProvidersService } from '../../services/getProviders.service';
import { getAllProductsService } from '../../services/getProducts.service'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { a11yProps, TabPanel } from '../../components/TabsComponent';
import { SocketContext } from '../../helpers/socket';
import ProviderDiscounts from './ProviderDiscounts';
import ConfirmationModal from '../../components/ConfirmationModal';
import { dieselCodeAction, gasolineCodeAction, lpgCodeAction, aviationFuelCodeAction } from '../../redux/actions/codesAction';


//import all modals and libraries required


function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <Grid sx={{ display: 'flex' }} columnGap={5}>
                    <CircularProgress color="secondary" />
                    {props.value == 0 && <Typography style={{ fontWeight: 'bold' }}>Uploading Transaction File . . .</Typography>}
                    {props.value !== 0 && <Typography style={{ fontWeight: 'bold' }}>{props.message} . . .</Typography>}
                </Grid>
                {/* <LinearProgress sx={{
                    height: 25, marginTop: '5%',
                    "& .MuiLinearProgress-barColorPrimary": {
                        backgroundColor: "#cc0000",
                    },
                }} variant="determinate" {...props} /> */}
            </Box>
            {/* <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box> */}
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

//Stepper Form Steps defined here
const steps = ['Optimization Form', 'Provider Discounts', 'Select Codes', 'Upload Excel', 'Optimization Process'];



export default function OptimizationModal({ open, handleClose }) {
    const dispatch = useDispatch();
    const formData = useSelector((state) => state.finalReducers.form_details);
    //    const loginData = useSelector((state) => state.finalReducers.login_data);
    const loginData = JSON.parse(localStorage.getItem('loginData'));
    // Sample data for provider Discounts 

    function sortByProperty(property){  
        return function(a,b){  
           if(a[property] > b[property])  
              return 1;  
           else if(a[property] < b[property])  
              return -1;  
       
           return 0;  
        }  
     }


    useEffect(async () => {
        setLoader(true);
        toast.configure();
        const accessToken = loginData && loginData.accessToken;
        const allProviders = await getAllProvidersService(accessToken).then((data) => {
            return data;
        })
            .catch((err) => {
                toast.error('Something went wrong, Please refresh', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000
                });
                setLoader(false)
                navigate('/login')
                throw err
            });
        allProviders.data.map((data) => {
            data['checked'] = true;
            data['value'] = data.defaultDiscount;
        })
        const allCodes = await getAllProductsService(accessToken).then((data) => {
            return data;
        })
            .catch((err) => {
                toast.error(err.response.data.msg, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000
                });
                setLoader(false)
                navigate('/login')
                throw err
            });;

        allCodes['aviation fuel'].products.map((data) => {
            data['checked'] = true;
        });
        allCodes['gas'].products.map((data) => {
            data['checked'] = true;
        });
        allCodes['diesel'].products.map((data) => {
            data['checked'] = true;
        });
        allCodes['lpg'].products.map((data) => {
            data['checked'] = true;
        })
        dispatch(aviationFuelCodeAction(allCodes['aviation fuel'].products))
        dispatch(gasolineCodeAction(allCodes['gas'].products))
        dispatch(dieselCodeAction(allCodes['diesel'].products))
        dispatch(lpgCodeAction(allCodes['lpg'].products))
        dispatch(providerDiscountsAction(allProviders.data.sort(sortByProperty('providerCode'))));
        setLoader(false);
    }, [])

    const socket = useContext(SocketContext);

    const styles = {
        tabStyle: {
            color: '#cc0000',
            '&.MuiTab-root.Mui-selected': { color: '#cc0000' }
        }
    }

    const [loader, setLoader] = useState(false);

    //Define States for form inputs
    const [customerName, setCustomerName] = useState('');
    const startNewDate = new Date()
    const [startDate, setStartDate] = useState(moment(startNewDate.setMonth(startNewDate.getMonth() - 1)).format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment(new Date()).format('YYYY-MM-DD'))

    //Define changes events for form inputs
    const handleCustomerName = (e) => {
        setCustomerName(e.target.value);
    }

    const handleStartDateChange = (e) => {
        setStartDate(moment(e).format('YYYY-MM-DD'));
        dispatch(startDateAction(e));
    }
    const handleEndDateChange = (e) => {
        setEndDate(moment(e).format('YYYY-MM-DD'));
        dispatch(endDateAction(e));
    }

    const [errorText,setErrorText]=useState('Please enter all fields');
    const validateFormInputs=()=>{
        if(customerName==''){
            setErrorText('Customer Name is required')
            return false;
        }
        else if(!startDate || !endDate){
            setErrorText('Please select start and end date')
            return false;
        }
        else if(startDate>endDate){
            setErrorText('Start date should be less than End date')
            return false;
        }
        else{
            setErrorText('')
            return true
        }
    }
    const [changeHeader, setChangeHeader] = useState(false);

    useEffect(()=>{
        validateFormInputs()
    },[customerName,startDate,endDate])

    const [apiData, setApiData] = useState({});

    // Function to validate optimization form
    const validateOptimizationForm = () => {
        if (activeStep === 3 && excelErrors == false) {
            const loginData = JSON.parse(localStorage.getItem('loginData'));
            toast.configure();

            socket.on("COMM_SOCKET", (msg) => {
                const commSocketData = msg;
                console.log("COMM_SOCKET", msg)
                setProgressMessage(commSocketData.process);
                setProgress(commSocketData.progress)
            });

         
            submitFormData(formData, fileuploaded, loginData.accessToken).then((res) => {
                //setUrl(res.data.outputURL);
                // socket.on('POST_PROGRESS', (value) => {
                //     if (JSON.parse(value) === 100) {
                //         setLoader(true)
                //     }
                //     setProgress(value);
                // })
                console.log('!!!!!!!!!', res);
                const errorMsg=JSON.parse(res);
                    if(errorMsg.status==500){
                        toast.error('Something went wrong. Analysis Failed', {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000
                        });
                        setTimeout(()=>{
                            handleCancel();
                        },2000)
                    }
                setApiData(JSON.parse(res));
                socket.on('GET_SUMMARY', (value) => {
                    console.log('!!!!!', value)
                    
                    if (value !== null) {
                        setLoader(false);
                        setOptimizationResult(value.data);
                    }
                })

                socket.on("LAMBDA_CHANNEL",(msg)=>{
                    console.log("LAMBDA_CHANNEL", msg)
                    
                })
    



                // // get the status of an analysis
                // socket.on("END_ANALYSIS", (data) => {
                //     console.log("END_ANALYSIS", data)
                // })
            })
                .catch((err) => {
                    console.log(err);
                })
        }

        if (validateFormInputs()==true) {
            return true;
        }
        else if(errorText!==''){
            toast.configure();
            toast.error(errorText, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000
            });
            return false;
        }
    }

    // Function to multiselect provider discounts


    let updateProvidersSelected = [];
    let allProductCodes = [];

    // Declare state for optimization progress bar
    const [progress, setProgress] = React.useState(0);
    const [progressMessage, setProgressMessage] = React.useState('');
    //Function to start Optimzation Modal
    const updatedGasolineCode = useSelector((state) => state.finalReducers.gasoline_codes);
    const updatedDieselCode = useSelector((state) => state.finalReducers.diesel_codes)
    const updatedLPGCode = useSelector((state) => state.finalReducers.lpg_codes)
    const updatedAviationFuelCode = useSelector((state) => state.finalReducers.aviationfuel_codes)
    const updatedProvidersDiscount = useSelector((state) => state.finalReducers.provider_discounts)

    const [excelErrors, setExcelErrors] = useState(false);
    const handleError = (value) => {
        setExcelErrors(value);
        if (value === false) {
            updateProvidersSelected = [];
            allProductCodes = [];
            updatedProvidersDiscount.map((data) => {
                if (data.checked === true) {
                    updateProvidersSelected.push({ id:  data.id, defaultDiscount: data.value,code:data.providerCode })
                }
            })
            updatedGasolineCode.map((data) => {
                if (data.checked === true && allProductCodes.includes(data.id) === false) {
                    allProductCodes.push(data.id)
                }
            });
            updatedDieselCode.map((data) => {
                if (data.checked === true && allProductCodes.includes(data.id) === false) {
                    allProductCodes.push(data.id)
                }
            });
            updatedLPGCode.map((data) => {
                if (data.checked === true && allProductCodes.includes(data.id) === false) {
                    allProductCodes.push(data.id)
                }
            });
            updatedAviationFuelCode.map((data) => {
                if (data.checked === true && allProductCodes.includes(data.id) === false) {
                    allProductCodes.push(data.id)
                }
            });

            const FileUploaded = localStorage.getItem('FileUploaded');
            const radius = localStorage.getItem('radius')
            dispatch(optimizeFormAction({ 'customerName': customerName, 'startDate': startDate, 'endDate': endDate, 'providersSelected': updateProvidersSelected, 'productCodes': allProductCodes, 'radius': radius, 'file': FileUploaded }))
        }
    }

    const [fileuploaded, setFileUploaded] = useState({});
    const handleFileSelection = (file) => {
        setFileUploaded(file)
    }

    //Stepper form Logic
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    // useEffect(()=>{
    //     console.log(formData.file)
    //     if(formData.file!==null ||formData.file!==undefined ){
    //         setExcelErrors(false)
    //     }
    //     else{
    //         setExcelErrors(true)
    //     }
    // },[activeStep])

    //Stepper form next button click event logic
    const handleNext = () => {
        if (activeStep == 0) {
            const radius = localStorage.getItem('radius')
            dispatch(optimizeFormAction({ 'customerName': customerName, 'startDate': startDate, 'endDate': endDate, 'radius': radius, }))
        }
        if (activeStep == 3) {
            setProgress(0);
        }
        if (validateOptimizationForm() == true && excelErrors == false) {
            let newSkipped = skipped;
            if (isStepSkipped(activeStep)) {
                newSkipped = new Set(newSkipped.values());
                newSkipped.delete(activeStep);
            }
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setSkipped(newSkipped);
        }

    };

    //Stepper form back button logic
    const handleBack = () => {
        handleError(false);
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        dispatch(optimizeFormAction({}))
    };

    // const handleSkip = () => {
    //     if (!isStepOptional(activeStep)) {
    //         // You probably want to guard against something like this,
    //         // it should never occur unless someone's actively trying to break something.
    //         throw new Error("You can't skip a step that isn't optional.");
    //     }

    //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //     setSkipped((prevSkipped) => {
    //         const newSkipped = new Set(prevSkipped.values());
    //         newSkipped.add(activeStep);
    //         return newSkipped;
    //     });
    // };
    const [value, setValue] = React.useState(0);

    const [optimizationResult, setOptimizationResult] = useState({});
    let downloadUrl = optimizationResult && optimizationResult.data && optimizationResult.data.outputURL;

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [url, setUrl] = useState('');

    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const navigate = useNavigate();
    const handleConfirmModal = (confirmState) => {
        setOpenConfirmModal(false);
        if (confirmState === 'yes') {
            setActiveStep(0);
            window.location.reload()
            localStorage.removeItem('customerName');
            localStorage.removeItem('optimizationResult')
            handleClose();
        }
    }

    const handleCancel = () => {
        setActiveStep(0);
        window.location.reload()
        localStorage.removeItem('customerName');
        localStorage.removeItem('optimizationResult')
        handleClose();
    }

    const handleCrossClose = () => {
        if (activeStep === 4 && progress >= 95) {
            handleCancel()
        }
        handleClose();
    }

    // React.useEffect(() => {
    //     const timer = setInterval(() => {
    //         setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 10));
    //     }, 800);
    //     return () => {
    //         clearInterval(timer);
    //     };
    // }, [handleStartOptimization]);



    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth='md'
                open={open}
                aria-labelledby="responsive-dialog-title"
            >
                {/* Change Modal Header when Excel file is displayed */}
                <DialogTitle style={{ fontWeight: 'bold' }} id="responsive-dialog-title">
                    {(progress == 100 && changeHeader) ? 'Optimization Summary' : 'New Optimisation'}
                    <CloseIcon sx={{ margin: '1% 0% 0% 75%' }} onClick={handleCrossClose} />
                </DialogTitle>
                <DialogContent style={{ margin: '3% 3% 3% 3%' }}>
                    <Box sx={{ width: '100%' }}>
                        <Stepper activeStep={activeStep}>
                            {steps.map((label, index) => {
                                const stepProps = {};
                                const labelProps = {};
                                if (isStepSkipped(index)) {
                                    stepProps.completed = false;
                                }
                                return (
                                    <Step key={label} {...stepProps}
                                        sx={{
                                            '& .MuiStepIcon-root.Mui-active': {
                                                color: '#cc0000'
                                            }
                                            ,
                                            '& .MuiStepIcon-root.Mui-completed': {
                                                color: '#cc0000'
                                            }
                                        }}
                                    >
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                        {/* Display All steps completed when all steps are completed */}
                        {/* {activeStep === steps.length &&
                            <React.Fragment>
                                <Typography sx={{ mt: 2, mb: 1 }}>
                                    All steps completed - you&apos;re finished
                                </Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    <Button onClick={handleReset}>Reset</Button>
                                </Box>
                            </React.Fragment>
                        } */}
                        {activeStep == 0 &&
                            <OptimizationForm handleCustomerName={handleCustomerName} handleStartDateChange={handleStartDateChange} handleEndDateChange={handleEndDateChange} />
                        }

                        {activeStep == 1 &&
                            <ProviderDiscounts />
                        }
                        {activeStep == 2 &&
                            <Box sx={{ width: '100%', marginTop: '3%' }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs TabIndicatorProps={{style: {backgroundColor: "brown"}}} value={value} onChange={handleChange} aria-label="basic tabs example">
                                        <Tab style={styles.tabStyle} label="Gasoline Codes" {...a11yProps(0)} />
                                        <Tab style={styles.tabStyle} label="Diesel Codes" {...a11yProps(1)} />
                                        <Tab style={styles.tabStyle} label="LPG Codes" {...a11yProps(2)} />
                                        <Tab style={styles.tabStyle} label="Aviation Fuel Codes" {...a11yProps(3)} />
                                    </Tabs>
                                </Box>
                                <TabPanel value={value} index={0}>
                                    <FuelSelectionModal customerName={customerName} startDate={startDate} endDate={endDate} type='(Gasoline)' />
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <FuelSelectionModal customerName={customerName} startDate={startDate} endDate={endDate} type='(Diesel)' />
                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                    <FuelSelectionModal customerName={customerName} startDate={startDate} endDate={endDate} type='(LPG)' />
                                </TabPanel>
                                <TabPanel value={value} index={3}>
                                    <FuelSelectionModal customerName={customerName} startDate={startDate} endDate={endDate} type='(Aviation Fuel)' />
                                </TabPanel>
                            </Box>
                        }
                        {activeStep == 3 &&
                            <UploadFilesModal handleError={handleError} handleFileSelection={handleFileSelection} />
                        }

                        {activeStep == 4 &&
                            <>
                                <Grid sx={{ marginTop: '5%' }}>
                                    <Typography sx={{ fontSize: 15 }}><strong>Customer Name</strong> : {customerName}</Typography>
                                </Grid>
                                <Grid>
                                    <Typography sx={{ marginTop: '2%', fontSize: 15 }}><strong>Transaction timeline</strong> : {startDate} to {endDate} </Typography>
                                </Grid>
                                {apiData && apiData.status !== 200  &&
                                    <Box sx={{ width: '100%', margin: '10% 0% 5% 0%' }}>
                                        <LinearProgressWithLabel value={progress} message={progressMessage} apiData={apiData} />
                                    </Box>
                                }
                                {apiData && apiData.status == 200 &&
                                    <Grid sx={{ textAlign: 'center', padding: '5% 5% 5% 5%', marginTop: '5%', border: '1px solid black', borderRadius: '10px' }}>
                                        <Typography>Analysis is in progress</Typography>
                                        <Typography sx={{ marginTop: '1%' }}>Please check the history section once you receive a completion email.</Typography>
                                        <Typography sx={{ marginTop: '1%' }}>Wait for completion email before starting a new analysis.</Typography>
                                    </Grid>
                                }
                                {/* {loader &&
                                    <Backdrop
                                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                        open={loader}
                                    >
                                        <CircularProgress color="inherit" />
                                    </Backdrop>
                                } */}
                                {/* {

                                    optimizationResult && optimizationResult.outputURL &&
                                    <Grid style={{ marginTop: '3%' }}>
                                        <DisplayTable optimizationResult={optimizationResult.productSavings} />
                                    </Grid>
                                } */}
                            </>
                        }
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            {activeStep !== 0 && activeStep !== 4 &&
                                <Button
                                    onClick={handleBack}
                                    autoFocus component="label"
                                    sx={{
                                        textTransform: 'none', backgroundColor: '#cc0000', color: 'white', "&:hover": {
                                            backgroundColor: "#cc0000",
                                        },
                                        width: '80px',
                                        mr: 1
                                    }}
                                >
                                    Back
                                </Button>
                            }
                            <Box sx={{ flex: '1 1 auto' }} />
                            {/* {isStepOptional(activeStep) && (
                                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                    Skip
                                </Button>
                            )} */}

                            {activeStep !== 4 &&
                                <Button onClick={handleCancel} sx={{
                                    textTransform: 'none', backgroundColor: '#A9A9A9', color: 'white', "&:hover": {
                                        backgroundColor: "#A9A9A9",
                                    },
                                }}>
                                    {activeStep == 4 && progress == 100 ? 'Finish' : 'Cancel'}
                                </Button>
                            }
                            {activeStep == 4 && optimizationResult && optimizationResult.outputURL &&
                                <Button autoFocus component="label" sx={{
                                    textTransform: 'none', backgroundColor: '#cc0000', color: 'white', "&:hover": {
                                        backgroundColor: "#cc0000",
                                    },
                                    marginLeft: '3%'
                                }}>

                                    <a style={{ color: 'white', textDecoration: 'none' }} href={optimizationResult.outputURL}> Download Excel</a>
                                </Button>
                            }
                            {activeStep !== 4 &&
                                <Button onClick={handleNext} autoFocus component="label" sx={{
                                    textTransform: 'none', backgroundColor: '#cc0000', color: 'white', "&:hover": {
                                        backgroundColor: "#cc0000",
                                    },
                                    marginLeft: '3%',
                                    width: '80px'
                                }}>
                                    Next
                                </Button>
                            }
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
            <ConfirmationModal open={openConfirmModal} handleChange={handleConfirmModal} />
            {/* <UserContext.Provider value={{ customerName: customerName, transactionTimeline: transactionTimeline }}>
                {openExcelModal && <UploadExcel open={openExcelModal} handleClose={handleExcelModalClose} />}
            </UserContext.Provider> */}

        </div>
    );
}
