import { BACKEND_URL } from '../constants/constants';

const axios = require('axios');
const FormData = require('form-data');
const fs = require('fs');

export function submitFormData (submitData, file,accessToken) {
  return new Promise(function (resolve, reject) {
  // const file=localStorage.getItem('FileUploaded');
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer ".concat(accessToken));

  var formdata = new FormData();
  formdata.append("providers", JSON.stringify(submitData.providersSelected));
  formdata.append("file", file, "transactions_modified.xlsx");
  formdata.append("radius", submitData.radius);
  formdata.append("customerName", submitData.customerName);
  formdata.append("transactionStartDate", submitData.startDate);
  formdata.append("transactionEndDate", submitData.endDate);
  formdata.append("productCodes", JSON.stringify(submitData.productCodes));


  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  };

  fetch(BACKEND_URL+"/api/v2/admin/analysis/new", requestOptions)
    .then(response => response.text())
    .then(result => {
      resolve(result);
    }
    )
    .catch(error => console.log('error', error))

  })
  }