import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';



export function DisplayTable({optimizationResult,modalContent}) {
  const [renderTable,setRenderTable]=React.useState(optimizationResult?optimizationResult:modalContent)
  return (
    <TableContainer component={Paper} style={{ border: '1.5px solid black',borderRadius:10 }}>
      <Table aria-label="simple table" >
        <TableHead style={{ backgroundColor: '#D3D3D3' }}>
          <TableRow hover={true}>
            <TableCell style={{ color: '#cc0000', fontWeight: 'bold' }} align='center'>Product</TableCell>
            <TableCell style={{ color: '#cc0000', fontWeight: 'bold' }} align='center'>Anticipated Savings</TableCell>
          </TableRow>
        </TableHead>
        <TableBody style={{ backgroundColor: '#F5F5F5' }}>
          {renderTable && renderTable.productSavings.map((row) => (
            <TableRow
              hover={true}
              key={row.product}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align='center' component="th" scope="row" style={{ fontWeight: 'bold' }}>
                {row.product.charAt(0).toUpperCase() + row.product.slice(1)}
              </TableCell>
              <TableCell align='center'>{row.savings?row.savings.toFixed(2):0}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}