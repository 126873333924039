import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';


export default function DataTableComponent({ rows, columns,handleOnCellClick,customStyles }) {
    return (
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
                style={customStyles}
                rowsPerPageOptions={[5]}
                onCellClick={handleOnCellClick}
            />
    );
}
