import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { validateOTPService } from "../../services/forgotPassword.service";


const useStyles = {
  textField: {
    "& .MuiOutlinedInput-root": {
      color: 'green',
      borderRadius: '40px !important',
    },
    marginTop: "5%",
    input: {
      color: "#000000",
      fontWeight: "bold",
    },
  }
};


function VerifyOtpModal(props) {


  const [otpValue, setOtpValue] = React.useState(0);
  const [loader, setLoader] = React.useState(false);


  const handleVerifyOtp = () => {
    toast.configure();
    console.log('!!!!!!!!', otpValue)
    const loginData = JSON.parse(localStorage.getItem('loginData'));
    const emailAddress = props.email;
    setLoader(true);
    validateOTPService({ email_address: emailAddress, otp: otpValue }).then((data) => {
      console.log(data);
      if (data.status == true) {
        setLoader(false);
        toast.success("OTP Verified", {
          position: toast.POSITION.TOP_LEFT,
          autoClose: 2000
        })
        props.handleCloseDialog()
      }
      else {
        setLoader(false)
        toast.warning("Incorrect OTP", {
          position: toast.POSITION.TOP_LEFT,
          autoClose: 2000
        });
      }
    })
  }

  return (
    <Dialog
      open={props.open}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle sx={{ fontWeight: "bold" }} id="responsive-dialog-title">
        Please Enter OTP Received
      </DialogTitle>
      <DialogContent>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TextField
            size="small"
            placeholder="Enter OTP"
            style={useStyles.textField}
            onChange={(e) => setOtpValue(e.target.value)}
          />
        </Grid>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "3%" }}>
        <Button
          sx={{ textTransform: "none", fontSize: 15, backgroundColor: '#cc0000' }}
          variant="contained"
          autoFocus
          onClick={handleVerifyOtp}
        >
          Verify OTP
        </Button>
      </DialogActions>
      {loader &&
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      }
    </Dialog>
  );
}

export default VerifyOtpModal;
