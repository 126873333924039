import { ADD_NEW_CODE, END_DATE, FILE_SELECTED, GET_CUSTOMERS, OPTIMIZATION_FORM, OPTIMIZATION_RESULT, PROVIDERS_DISCOUNTS, SEND_NOTIFICATION, START_DATE } from "./actionTypes";

export const optimizeFormAction=(data)=>{
    return{
        type:OPTIMIZATION_FORM,
        payload:data
    }
}

export const startDateAction=(data)=>{
    return {
        type:START_DATE,
        payload:data
    }
}

export const endDateAction=(data)=>{
    return {
        type:END_DATE,
        payload:data
    }
}

export const addNewCodeAction=(data)=>{
    return {
        type : ADD_NEW_CODE,
        payload:data
    }
}

export const providerDiscountsAction=(data)=>{
    return {
        type : PROVIDERS_DISCOUNTS,
        payload:data
    }
}


export const getCustomerAction=(data)=>{
    return {
        type : GET_CUSTOMERS,
        payload:data
    }
}

export const fileSelectedAction=(data)=>{
    return{
        type:FILE_SELECTED,
        payload:data
    }
}

export const sendNotificationAction=(data)=>{
    return{
        type:SEND_NOTIFICATION,
        payload:data
    }
}