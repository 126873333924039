import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import OptimizationModal from '../containers/OptimizationModal/OptimizationModal';
import Avatar from '@mui/material/Avatar';
import { useNavigate } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import MessageIcon from '@mui/icons-material/Message';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';

const drawerWidth = 230;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function SideNavDrawer(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);

  const loginData=JSON.parse(localStorage.getItem('loginData'));


  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  var styles = {
    header: {
      mr: 2, display: { xs: 'none', md: 'flex' }, color: 'black', fontSize: '1.2vw', fontWeight: 'bold', marginLeft: '1%',
    },

    button: {
      color: 'red', backgroundColor: 'white', marginLeft: 'auto', textTransform: 'none', padding: '0.5% 1% 0.5% 1%', border: '1px solid red'
    }
  }


  let navigate = useNavigate();

  const handleClose = () => {
    localStorage.removeItem('lpgCodesSelected');
    localStorage.removeItem('gasolineCodesSelected');
    localStorage.removeItem('dieselCodesSelected');
    localStorage.removeItem('aviationCodesSelected');
    localStorage.removeItem('startDate');
    localStorage.removeItem('endDate');
    localStorage.removeItem('FileUploaded');
    setOpenModal(false);
  }

  const handleOptimization = () => {
    setOpenModal(true)
  }

  //Function to create string to color for Username and topRight
  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }


  //Function to convert username to Avatar at topRight
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openAvatar = Boolean(anchorEl);

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleAvatarClose = () => {
    setAnchorEl(null);
  }

  const handleLogout = () => {
    localStorage.removeItem('userEmail');
    localStorage.removeItem('loginData');  
    localStorage.removeItem('optimizationResult');  
    handleClose();
    navigate('/login')
    window.location.reload();
  }


  const signedinUser = localStorage.getItem('userEmail');

  const listItems = [
    { name: 'Home', link: '/home', icon: <HomeIcon style={{ fontSize: '28px',color:'#cc0000' }} /> },
    { name: 'Cost Optimizer', link: '/optimizer', icon: <AutoFixNormalIcon style={{ fontSize: '28px',color:'#cc0000' }} /> },
    { name: 'Messages', link: '/notifications', icon: <MessageIcon style={{ fontSize: '25px',color:'#cc0000'}}/> },
    { name: 'Report', link: '/report', icon: <AssessmentIcon style={{color:'#cc0000'}}/> },
    { name: 'Logout', link: '/login', icon: <LogoutIcon style={{color:'#cc0000'}}/> },
  ]


  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{ backgroundColor: '#E0E0E0' }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }),color:'black' }}
          >
            <MenuIcon />
          </IconButton>
          <img src='appstore.png' width={50} height={50} style={{ margin: '0.5% 0% 0.5% 0%' }} onClick={() => navigate('/home')} />
          <Typography
            component="div"
            style={styles.header}
          >
            HPC Console
          </Typography>

          {/* <Button style={styles.button} onClick={handleOptimization}>New Optimization</Button> */}
          {/* Display Avatar Menu Icons onclick */}
          {/* <Stack direction="row" sx={{ marginLeft: '3%' }}>
            <Avatar {...stringAvatar('Gautam Kothari')} style={{ width: 40, height: 40, backgroundColor: '#cc0000' }} onClick={handleAvatarClick} />
            <Menu
              id="fade-menu"
              MenuListProps={{
                'aria-labelledby': 'fade-button',
              }}
              anchorEl={anchorEl}
              open={openAvatar}
              onClose={handleAvatarClose}
              TransitionComponent={Fade}
            >
              {signedinUser === 'superadmin@vdart.com' && <MenuItem onClick={handleViewReport}>Report</MenuItem>}
              <MenuItem onClick={handleAvatarClose}>My account</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </Stack> */}
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,

            backgroundColor: '#E0E0E0',
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
        <Avatar {...stringAvatar(loginData.data.fullName)} style={{ width: 40, height: 40, backgroundColor: '#cc0000',marginRight:'30px' }} onClick={handleAvatarClick} />
          <b>Hello, Admin</b>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {listItems.map((data) => {
            return (
              <ListItem key={data.name} disablePadding onClick={data.name==='Logout'?handleLogout:() => navigate(data.link)}>
                <ListItemButton>
                  <ListItemIcon>
                    {data.icon}
                  </ListItemIcon>
                  <ListItemText primary={data.name} />
                </ListItemButton>
              </ListItem>
            )
          })}
        </List>
        <Divider />
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        {props.component}
      </Main>
      <OptimizationModal open={openModal} handleClose={handleClose} />
      {/* {!openModal && <SessionTimeout isAuthenticated={true} logOut={handleLogout} />} */}
    </Box>
  );
}
