import { Backdrop, Box, Button, Grid, Paper, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import Auth from '../../helpers/Auth';
import ForgotPassword from '../ForgotPassword/ForgotPassword';
import { useMediaQuery } from 'react-responsive';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loginService } from '../../services/login.service';
import { loginUserAction } from '../../redux/actions/loginAction'
import { useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';

//Create styles for Login Page
var styles = {
    loginGrid: {
        color: 'black', backgroundColor: '#F0F0F0', margin: '5% -10% 0% -13vw', padding: '2% 4% 5% 4%', borderRadius: 20
    },

    loginButton: {
        textTransform: 'none', backgroundColor: 'red', color: 'white', width: '87%', marginTop: '10%'
    },
}


function LoginScreen() {

    let navigate = useNavigate();
    const dispatch = useDispatch();

    // responsiveness variable for loginScreen
    const loginModal = useMediaQuery({ query: '(max-width: 900px)' })
    const loginButtonWidth = useMediaQuery({ query: '(min-width: 1345px)' })

    const [loader, setLoader] = useState(false);

    const handleLogin = () => {
        //Use react toastify to show errors
        toast.configure();

        if (!userCredentials.user_name || !userCredentials.password) {
            toast.error("Please Enter all the fields!", {
                position: toast.POSITION.TOP_LEFT,
                autoClose: 2000
            });
        }
        else if (userCredentials.user_name != null && userCredentials.password != null) {
            setLoader(true);
            loginService(userCredentials).then((validLoginData) => {
                if (validLoginData && validLoginData.status === true) {
                    Auth.authenticate();
                    setLoader(false);
                    dispatch(loginUserAction(validLoginData))
                    localStorage.setItem('loginData', JSON.stringify(validLoginData))
                    //Store user in localstorage for staying loggedin when page refreshed
                    localStorage.setItem('userEmail', userCredentials.user_name)
                    navigate('/home')
                }
                else {
                    toast.error("Invalid Credentials!", {
                        position: toast.POSITION.TOP_LEFT,
                        autoClose: 2000
                    });
                    setLoader(false)
                }
            })
            .catch((err)=>{
                console.log(err)
                toast.error('Something went wrong', {
                    position: toast.POSITION.TOP_LEFT,
                    autoClose: 2000
                });
                setLoader(false)
            })
        }

    }


    const [userCredentials, setUserCredentials] = useState({
        user_name: '',
        password: ''
    });

    //Storing email and password in userCredentials
    const [codeErrorText, setCodeErrorText] = useState('');
    const handleChange = (e) => {
        if (e.target.value == '') {
            setCodeErrorText('*Required field')
        }
        else {
            setUserCredentials({ ...userCredentials, [e.target.name]: e.target.value })
            setCodeErrorText('')
        }
    }

    //Forgot password state
    const [openResetPass, setOpenResetPass] = useState(false);
    const handleCloseDialog = () => {
        setOpenResetPass(false)
    }

    return (
        <>
            <Grid sx={{ display: 'flex' }} item xs={12}>
                <Grid>
                    <img src='backgroundImage.jpeg' width={loginModal ? '280%' : '85%'} height='100%' />
                </Grid>
                <Grid sx={{ margin: loginModal ? '3% 22% 0% 2%' : '3% 2% 0% 2%', paddingBottom: loginModal ? '30%' : '' }}>
                    <img src='appstore.png' width={120} height={120} style={{ margin: '0% 0% 5% -75%' }} />
                    <Grid style={styles.loginGrid}>
                        <Typography sx={{ fontWeight: 'bold', margin: '10% 0% 0% 0%', fontSize: '2vw', textAlign: 'center' }}>Login</Typography>
                        <Grid sx={{ margin: '15% 0% 0% 6.5%' }}>
                            <Typography sx={{ fontWeight: 'bold',fontSize: '1.2vw' }}>Email</Typography>
                            <TextField placeholder='name@domain.com' onChange={handleChange}
                                name='user_name' size='small' sx={{ width: loginModal ? '90%' : '92%'}} InputProps={{
                                    sx: {
                                        "& .MuiInputBase-input": {
                                            background: "white !important",
                                            fontSize:'1.2vw'
                                        },
                                    }

                                }} error={codeErrorText} required></TextField>
                        </Grid>
                        <Grid sx={{ margin: '5% 0% 0% 6.5%' }}>
                            <Typography sx={{ fontWeight: 'bold',fontSize: '1.2vw' }}>Password</Typography>
                            <TextField placeholder='Password' type='password' size='small' onChange={handleChange}
                                name='password' sx={{width: loginModal ? '90%' : '92%'}} InputProps={{
                                    sx: {
                                        "& .MuiInputBase-input": {
                                            background: "white !important",
                                            fontSize:'1.2vw'
                                        },
                                    }
                                }} error={codeErrorText} required></TextField>
                        </Grid>

                        <Button sx={{ margin: '15% 0% 0% 6.5%', width: loginButtonWidth ? '50% !important' : '87% !important',fontSize:'1.2vw' }} style={styles.loginButton} variant='filled' onClick={handleLogin}>Login</Button>
                        <Typography sx={{ margin: '10% 0% 0% 0%', textAlign: 'center', color: 'red', cursor: 'pointer',fontSize:'1.2vw' }} onClick={() => setOpenResetPass(true)}>Forgot password?</Typography>
                    </Grid>
                </Grid>
                {/* Open Forgot password modal after clicking on forgot password link */}
                <ForgotPassword
                    open={openResetPass}
                    handleCloseDialog={handleCloseDialog}
                />
            </Grid>
            {loader &&
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loader}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
        </>
    )
}

export default LoginScreen