import {  END_DATE, START_DATE } from "../actions/actionTypes";


const startNewDate= new Date();
const initialState = {
    startDate:startNewDate.setMonth(startNewDate.getMonth() - 1),
    endDate: new Date(),
}

const dateReducer = (state = initialState, { type, payload }) => {

    switch (type) {
        case START_DATE:
            return {
                ...state,
                startDate: payload
            }

        case END_DATE:
            return {
                ...state,
                endDate: payload
            }
        default:
            return state;
    }
}



export default dateReducer