import axios from "axios";
import { BACKEND_URL } from "../constants/constants";

export function forgotPasswordService(emailId) {
    return new Promise(function (resolve, reject) {
        axios.post(BACKEND_URL + '/api/v2/admin/forgotPassword', {email_address:emailId})
            .then((res) => {
                resolve(res.data);
                
            })
            .catch((err) => {
                reject(err);
                throw err;
            })
    })
}

export function validateOTPService(data){
    return new Promise(function (resolve, reject) {
        axios.post(BACKEND_URL + '/api/v2/admin/checkOTP', data)
            .then((res) => {
                resolve(res.data);                
            })
            .catch((err) => {
                reject(err);
                throw err;
            })
    })
}


export function changePasswordService(data){
    return new Promise(function (resolve, reject) {
        axios.post(BACKEND_URL + '/api/v2/admin/setPassword', data)
            .then((res) => {
                resolve(res.data);                
            })
            .catch((err) => {
                console.log(err)
                reject(err);
                throw err;
            })
    })
}