import React, { useEffect } from 'react'
import { Checkbox, Divider, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import { useState } from 'react';
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux';
import { aviationFuelCodeAction, dieselCodeAction, gasolineCodeAction, lpgCodeAction } from '../redux/actions/codesAction';
import { providerDiscountsAction } from '../redux/actions/optimizationFormAction';

function MultiSelectComponent({ data, handleMultiselect, enableTextField, selectAll, productType }) {

    const [providerValue, setProviderValue] = React.useState(0);
    const providersID = localStorage.getItem('providersID');


    const gasolineCodes = useSelector(state => state.finalReducers.gasoline_codes);
    const dieselCodes = useSelector(state => state.finalReducers.diesel_codes);
    const lpgCodes = useSelector(state => state.finalReducers.lpg_codes);
    const aviationFuel = useSelector(state => state.finalReducers.aviationfuel_codes);
    const providerDiscounts = useSelector(state => state.finalReducers.provider_discounts);

    const [tempValue, setTempValue] = useState(data.value);
    const handleProviderValue = (value, data) => {
        // const providersID2 = localStorage.getItem('providersID');
        // setProviderValue(value);
        // if (providersID2 && providersID2.includes(data.id)) {
        //     handleMultiselect(true, data.providerName, data.id, value);
        // }
        setTempValue(value)
        providerDiscounts.map((provider) => {
            if (provider.providerName === data.providerName)
                provider.value = value
        })
        dispatch(providerDiscountsAction(providerDiscounts))

    }

    const [checked, setChecked] = useState(data.checked);

    const handleMultiselectCodes = (e, providerData) => {
        setChecked(!checked)
        if (e.target.checked === false) {
            providerDiscounts.map((data) => {
                if (data.providerName === providerData.providerName) {
                    data.checked = false;
                }
            })
            dispatch(providerDiscountsAction(providerDiscounts))
        }
        else {
            providerDiscounts.map((data) => {
                if (data.providerName === providerData.providerName) {
                    data.checked = true;
                }
            })
            dispatch(providerDiscountsAction(providerDiscounts))
        }
        //  handleMultiselect(e.target.checked, providerName, providerId, providerValue);
    }



    const dispatch = useDispatch();

    const handleCodeChange = (e, dataName) => {
        switch (productType) {
            case '(Gasoline)':
                setChecked(!checked)
                if (e.target.checked === true) {
                    gasolineCodes.map((data) => {
                        if (data.code === dataName) {
                            data.checked = true;
                        }
                    })
                    dispatch(gasolineCodeAction(gasolineCodes))
                }
                else {
                    gasolineCodes.map((data) => {
                        if (data.code === dataName) {
                            data.checked = false;
                        }
                    })
                    dispatch(gasolineCodeAction(gasolineCodes))
                }
                break;
            case '(Diesel)':
                setChecked(!checked)
                if (e.target.checked === true) {
                    dieselCodes.map((data) => {
                        if (data.code === dataName) {
                            data.checked = true;
                        }
                    })
                    dispatch(dieselCodeAction(dieselCodes))
                }
                else {
                    dieselCodes.map((data) => {
                        if (data.code === dataName) {
                            data.checked = false;
                        }
                    })
                    dispatch(dieselCodeAction(dieselCodes))
                }
                break;
            case '(LPG)':
                setChecked(!checked)
                if (e.target.checked === true) {
                    lpgCodes.map((data) => {
                        if (data.code === dataName) {
                            data.checked = true;
                        }
                    })
                    dispatch(lpgCodeAction(lpgCodes))
                }
                else {
                    lpgCodes.map((data) => {
                        if (data.code === dataName) {
                            data.checked = false;
                        }
                    })
                    dispatch(lpgCodeAction(lpgCodes))
                }
                break;
            case '(Aviation Fuel)':
                setChecked(!checked)
                if (e.target.checked === true) {
                    aviationFuel.map((data) => {
                        if (data.code === dataName) {
                            data.checked = true;
                        }
                    })
                    dispatch(aviationFuelCodeAction(aviationFuel))
                }
                else {
                    aviationFuel.map((data) => {
                        if (data.code === dataName) {
                            data.checked = false;
                        }
                    })
                    dispatch(aviationFuelCodeAction(aviationFuel))
                }
                break;
        }
    }


    // useEffect(()=>{
    //     gasolineCodes.map((data)=>{
    //         if(data.checked===true){
    //             setChecked(true)  
    //         }
    //         else{
    //             setChecked(false);  
    //         }
    //     })
    // },[])



    return (
        <React.Fragment>
            <ListItem component="div" dense >
                {enableTextField &&

                    <FormControl>
                        <FormControlLabel control={<Checkbox
                            checked={data.checked}
                            style={{ color: '#cc0000' }}
                            onChange={(e) => handleCodeChange(e, data.code)} />} label={<span style={{ fontSize: 14 }}>{data.description} ({data.code})</span>} />
                    </FormControl>
                }
                {!enableTextField &&
                    <>
                        <FormControl>
                            <FormControlLabel control={<Checkbox
                                style={{ color: '#cc0000' }}
                                checked={data.checked}
                                onChange={(e) => handleMultiselectCodes(e, data)} />} label={<span style={{ fontSize: 14 }}>{data.providerCode} ({data.providerName})</span>} />
                        </FormControl>
                        <TextField
                            onChange={(event) =>
                                event.target.value < 0
                                    ? (event.target.value = 0)
                                    : handleProviderValue(event.target.value, data)
                            }
                            inputProps={{ step: ".01" }}
                            onWheel={(e) => e.target.blur()}
                            value={data.value} variant='standard' type='number' InputProps={{ disableUnderline: true, style: { color: 'white', margin: '0% 10% 0% 15%', fontSize: 14 } }} sx={{
                                borderRadius: 20, backgroundColor: '#cc0000', marginLeft: 'auto', marginRight: '5%', width: '10%', "&:hover": {
                                    backgroundColor: "#cc0000",
                                },
                            }}></TextField>
                    </>
                }
            </ListItem>
        </React.Fragment>
    )
}

export default MultiSelectComponent