import axios from "axios";
import { BACKEND_URL } from '../constants/constants'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function notificationService(notificationData) {
    const loginData = JSON.parse(localStorage.getItem('loginData'));
    const accessToken = loginData && loginData.accessToken;
    return new Promise(function (resolve, reject) {
        axios.post(BACKEND_URL + '/api/v2/admin/notifications/sendNew', notificationData, {
            headers: {
                'Authorization': "Bearer ".concat(accessToken)
            }
        })
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                toast.configure();
                reject(err);
                throw err;
            })
    })
}