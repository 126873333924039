import axios from "axios";
import React from "react";
import { BACKEND_URL } from "../constants/constants";
export function getAllProductsService(accessToken) {
    return new Promise(function (resolve, reject) {
        axios.get(BACKEND_URL + '/api/v2/admin/analysis/products/getAll?formatted=true', {
            headers: {
                'Authorization': "Bearer ".concat(accessToken)
            }
        })
            .then((res) => {
                resolve(res.data.data);
            })
            .catch((err) => {
                reject(err);
                throw err;
            })
    })
}


export function addProductService(accessToken, customCode, type) {

    var raw = JSON.stringify({
        "products": [
            {
                "code": customCode.codeName,
                "description": customCode.codeDescription,
                "product": type
            }
        ]
    });


    // const products = [
    //     {
    //         code: customCode.codeName,
    //         description: customCode.codeDescription,
    //         product: 'Gas'
    //     }
    // ]
    return new Promise(function (resolve, reject) {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer ".concat(accessToken));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };



        fetch(BACKEND_URL + "/api/v2/admin/analysis/products/addNew", requestOptions)
            .then(response => response.text())
            .then(result => {
                resolve(result);
            }
            )
            .catch(error => console.log('error', error))

    })
}